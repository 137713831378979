<template>
  <div class="tier-container">
    <h1 class="tier-header">Album Tiers</h1>
    <div class="tier-content">
      <tierRow />
    </div>
    <div class="changelog-link">
      <router-link to="/changelog">Changelog</router-link>
    </div>
  </div>
</template>

<script>
import tierRow from './tierRow.vue';

export default {
  name: 'TierContainer',
  components: {
    tierRow
  }
};
</script>

<style scoped>
.tier-container {
  margin: 0 auto; 
  padding: 20px;
  background-color: #292929;
  border-radius: 8px; 
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

h1 {
  margin: 0!important;
}

.tier-header {
  text-align: left;
  font-size: 38px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #ffffff; /* Dark grey for contrast */
}

.tier-content {
  
}

.changelog-link {
  margin-top: 20px;
  text-align: right; /* Aligns the link to the right */
}

.changelog-link a {
  color: #00bcd4; /* Color for the link */
  text-decoration: none;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #333; /* Background color for the link */
  transition: background-color 0.3s ease;
}

.changelog-link a:hover {
  background-color: #00bcd4; /* Background color on hover */
  color: #292929; /* Text color on hover */
}

@media (max-width: 768px) {
  .tier-container {
    padding: 10px;
  }

  .tier-header {
    font-size: 28px;
    text-align: center;
  }

  .changelog-link {
    text-align: center; /* Center align for mobile screens */
  }
}
</style>
