<template>
    <router-view />
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
body {
  background-color: #0c0c0c;
  color: #f3f3f3;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
}
</style>
