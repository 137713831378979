<template>
  <div class="modal-overlay" @click.self="$emit('close')">
    <div class="modal-content">
      <button class="close-btn" @click="$emit('close')">×</button>
      <div class="album-info">
        <img :src="album.img" alt="Album cover">
        <div class="text-info">
          <p class="artist">{{ album.artist }}</p>
          <p class="title">{{ album.title }}</p>
          <p class="spotify-url">
            <a :href="album.spotify_url" target="_blank">Open on Spotify</a>
          </p>
          <p v-if="veto" class="veto-info">Vetoed by: {{ veto_by }}</p>
        </div>
      </div>
      <p class="tier">{{ album.tier }} Tier</p>
      <p class="submitted-by">Submitted by: {{ album.submitted_by }}</p>
      <p class="reviewed-at">Reviewed at: {{ album.reviewed_at }}</p>
      <div class="individual-tiers">
        <h3>Individual Tier Ratings:</h3>
        <ul>
          <li v-for="(tier, name) in album.individual_tiers" :key="name">{{ name }}: {{ tier }}</li>
        </ul>
      </div>
      <router-link :to="'/edit-album/' + album.id" class="edit-link">
          Edit
        </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    album: Object,
    veto: Boolean,
    veto_by: String
  },
  name: 'albumDetailsModal',
  methods: {
    emitAlbumClicked() {
      this.$emit('albumClicked', this.album);
    }
  }
}
</script>

<style scoped>
/* Existing styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.modal-content {
  position: relative;
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  max-height: 80vh;
  overflow-y: auto;
  width: 80%;
  max-width: 600px;
}

.album-info {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.album-info img {
  width: 200px;
  margin-right: 20px;
}

.text-info p {
  margin: 4px 0;
}

@media (max-width: 600px) {
  .album-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .album-info img {
    width: 100%;
    max-width: 250px;
    margin: 0 auto 10px;
  }

  .text-info {
    align-items: center;
    width: 100%;
    text-align: center;
  }
}

.modal-content .artist {
  font-weight: bold;
  font-size: 20px;
}

.modal-content .title {
  font-size: 20px;
}

.modal-content .tier {
  font-size: 18px;
  font-weight: bold;
}

.veto-info {
  color: red;
  font-weight: bold;
  padding-top: 10px;
}

.close-btn {
  position: absolute;
  top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  right: 5px;
  background: #fefbfb;
  border-radius: 10px;
  border: none;
  font-size: 32px;
  cursor: pointer;
}

.close-btn:hover {
  color: #ccc;
}

.modal-content .submitted-by,
.modal-content .reviewed-at,
.modal-content .spotify-url,
.modal-content .individual-tiers {
  margin-top: 5px;
}

.modal-content .individual-tiers h3 {
  margin-bottom: 5px;
}

.modal-content .individual-tiers ul {
  list-style-type: none;
  padding-left: 0;
}

.modal-content .individual-tiers li {
  margin-bottom: 5px;
}

.edit-link {
  font-size: 12px;
  color: #333;
}

.edit-link:hover {
  color: #007bff;
}
</style>
