<template>
  <div>
    <div class="filters">
      <div class="search-container">
        <input type="text" v-model="searchTerm" placeholder="Search by artist or title...">
      </div>
      <div class="filter-buttons">
        <button @click="setTierGrouping('overall')">Overall Rating</button>
        <button v-for="name in individualTierNames" :key="name" @click="setTierGrouping(name)">{{ name }}</button>
      </div>
      <div class="selected-tier-filter">
        <strong>{{ currentViewingText }}</strong>
      </div>
    </div>
    
    <div v-for="[tier, albums] in filteredAlbumsByTier" :key="tier" :class="['tier-container', tierClass(tier)]">
      <div class="tier-title">{{ tier }}</div>
      <div class="albums-scroll-wrapper">
        <div class="albums-scroll-container" ref="albumsContainerRef">
          <albumCover v-for="album in albums" :key="album.id" :album="album" :veto="album.veto === true" @albumClicked="handleAlbumClicked"></albumCover>
        </div>
      </div>
    </div>

    <albumDetailsModal v-if="selectedAlbum" :album="selectedAlbum" :veto="selectedAlbum.veto" :veto_by="selectedAlbum.veto_by" @close="selectedAlbum = null" />
  </div>
</template>

<script setup>
import { useTierRow } from '../composables/useTierRow';
import albumCover from './albumCover.vue';
import albumDetailsModal from './albumDetailsModal.vue';

const {
  albums,
  currentViewingText,
  filteredAlbumsByTier,
  individualTierNames,
  searchTerm,
  selectedAlbum,
  albumsContainerRef,
  handleAlbumClicked,
  tierClass,
  setTierGrouping,
} = useTierRow();

console.log("Fetched albums in tierRow:", albums.value); // Debugging line
</script>






<style scoped>
  .tier-title {
    font-size: 20px;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    justify-content: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background: rgba(0, 0, 0, 0.473);
  }
  .tier-row {
    display: flex;
    align-items: stretch;
    text-align: left;
    background-color: rgb(41, 41, 41);
  }

  .filters {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }

  .filter-buttons {
    display: flex;
    flex-direction: row;
    margin-left: 5px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .filter-buttons button {
    background: black;
    color: white;
    padding: 15px;
    border: none;
    margin-right: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .filter-buttons button:hover {
    background: rgb(131, 131, 131);
    cursor: pointer;
  }

  .search-container {
    display: flex;
    margin-right: 5px;
    margin-left: 5px;
    min-width: 200px;
    margin-bottom: 10px;
  }

  .search-container input {
    width: 100%;
    font-size: 16px;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 14px;
    border: 1px solid #ccc;
  }

  .selected-tier-filter {
    display: flex;
    font-size: 24px; /* Larger text size */
    text-align: left;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    flex-basis: 100%;
  }

  @media (max-width: 600px) {
    .filters {
      flex-direction: column;
      align-items: stretch;
      margin-top: 5px;
    }

    .filter-buttons {
      justify-content: center;
    }

    .filter-buttons button {
      flex: 1 1 auto;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    .selected-tier-filter {
      font-size: 18px;
      justify-content: center;
      text-align: center;
    }

    .search-container {
      margin-right: 5px;
      margin-bottom: 10px;
    }

    .search-container input {
      padding: 15px 20px; /* Increase padding for mobile */
      font-size: 16px;    /* Adjust font size for mobile */
    }
  }

  .albums-scroll-wrapper {
    position: relative;
    overflow: hidden; /* Ensures the gradient doesn't extend the scroll area */
    margin-bottom: 15px;
  }

  .albums-scroll-container {
    display: flex;
    overflow-x: auto;
    gap: 10px;
  }

  .albums-scroll-wrapper::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100px;
    pointer-events: none;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2));
  }

  /* tier-specific styles */
  .tier-s {
    background-color: #E63946
  }

  .tier-a {
    background-color: #F4A261;
  }

  .tier-b {
    background-color: #F9C74F;
  }

  .tier-c {
    background-color: #2A9D8F;
  }

  .tier-d {
    background-color: #457B9D;
  }

  .tier-e {
    background-color:#9C89B8;
  }

  .tier-tiering {
    background-color: #FAD2E1;
  }

  .tier-not {
    background-color: black;
  }

  .tier-now {
    background-color:  #00ac4e; 
  }

  /* Adjustments for mobile */
  @media (max-width: 600px) { /* Adjust this value based on your needs */
    .filter-buttons {
      flex-wrap: wrap; /* Allow the buttons to wrap */
      justify-content: center; /* Center-align the buttons for a more balanced look */
    }

    .filter-buttons button {
      padding: 10px 15px; /* Adjust padding to better fit on smaller screens */
      margin-bottom: 5px; /* Add a margin-bottom for wrapped buttons */
      font-size: 14px; /* Optional: adjust font size for smaller screens */
    }

    .albums-scroll-container {
      gap: 5px; /* Reduce gap on smaller screens */
    }

    /* Ensure the selected-tier-filter text scales down on smaller screens */
    .selected-tier-filter {
      font-size: 18px; /* Smaller font size for mobile */
      justify-content: center; /* Center the text for better alignment */
      padding: 5px 0; /* Adjust padding */
    }
  }
</style>
