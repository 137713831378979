import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './views/HomePage.vue';
import EditAlbum from './views/EditAlbum.vue';
import ChangeLog from './views/ChangeLog.vue';

const routes = [
  { path: '/', name: 'HomePage', component: HomePage },
  { path: '/edit-album/:id', name: 'EditAlbum', component: EditAlbum, props: true },
  { path: '/changelog', name: 'ChangeLog', component: ChangeLog }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
