<template>
  <div>
    <TierContainer />
  </div>
</template>

<script>
import TierContainer from '../components/tierContainer.vue';

export default {
  name: 'HomePage',
  components: {
    TierContainer
  }
};
</script>
