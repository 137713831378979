import { ref, computed, onMounted } from 'vue';
import apiService from '../apiService';

export function useTierRow() {
  const albums = ref([]);
  const loading = ref(true);
  const tierOrder = ["Now Listening To", "S", "A", "B", "C", "D", "E", "F", "Tiering Herrie", "?", "Not rated yet"];
  const currentTierGrouping = ref('overall');
  const selectedAlbum = ref(null);
  const albumsContainerRef = ref(null);
  const searchTerm = ref('');

  const fetchAlbums = async () => {
    try {
      const response = await apiService.fetchAlbums();
      albums.value = response;
      console.log("Fetched albums in tierRow:", albums.value); // Debugging line
    } catch (error) {
      console.error("There was an error fetching the albums!", error);
    } finally {
      loading.value = false;
    }
  };

  const individualTierNames = computed(() => {
    const names = new Set();
    albums.value.forEach(album => {
      Object.keys(album.individual_tiers || {}).forEach(name => names.add(name));
    });
    return Array.from(names);
  });

  function handleAlbumClicked(album) {
    selectedAlbum.value = album;
  }

  function tierClass(tier) {
    return `tier-${tier.toLowerCase()}`;
  }

  function setTierGrouping(grouping) {
    currentTierGrouping.value = grouping;
  }

  const currentViewingText = computed(() => {
    return currentTierGrouping.value === 'overall' ? 'Overall Rating' : currentTierGrouping.value;
  });

  const filteredAlbumsByTier = computed(() => {
    const tiers = {};
    const searchLowerCase = searchTerm.value.toLowerCase();
    albums.value.forEach((album) => {
      let tier = album.tier;
      if (currentTierGrouping.value !== 'overall') {
        tier = album.individual_tiers[currentTierGrouping.value] || tier;
      }
      if (
        album.artist.toLowerCase().includes(searchLowerCase) ||
        album.title.toLowerCase().includes(searchLowerCase)
      ) {
        if (!tiers[tier]) tiers[tier] = [];
        tiers[tier].push(album);
      }
    });
    return Object.entries(tiers).sort((a, b) => tierOrder.indexOf(a[0]) - tierOrder.indexOf(b[0]));
  });

  onMounted(() => {
    fetchAlbums(); // Fetch albums on mount
  });

  return {
    albums,
    loading,
    currentViewingText,
    filteredAlbumsByTier,
    individualTierNames,
    searchTerm,
    selectedAlbum,
    albumsContainerRef,
    handleAlbumClicked,
    tierClass,
    setTierGrouping,
  };
}
