<template>
    <div class="changelog-container">
      <h1>Change Log</h1>
      <div v-if="loading" class="loading">Loading...</div>
      <div v-else-if="error" class="error">{{ error }}</div>
      <div v-else>
        <ul>
          <li v-for="log in changelogs" :key="log.id" class="log-item">
            <div class="log-date">{{ formatDate(log.change_date) }}</div>
            <div class="log-content">
              <p v-if="log.change_type === 'Overall Rating Change'" class="log-text">
                <span class="log-type">{{ log.change_type }}:</span> 
                <span class="log-album">{{ log.artist }} - {{ log.title }}</span> 
                overall rating changed from <span class="log-rating">{{ log.old_rating }}</span> to <span class="log-rating">{{ log.new_rating }}</span>
              </p>
              <p v-else class="log-text">
                <span class="log-type">{{ log.change_type }}:</span> 
                <span class="log-reviewer"> {{ log.reviewer }}'s</span> rating for 
                <span class="log-album">{{ log.artist }} - {{ log.title }}</span> 
                changed from <span class="log-rating">{{ log.old_rating }}</span> to <span class="log-rating">{{ log.new_rating }}</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  import apiService from '../apiService';
  
  export default {
    name: 'ChangeLog',
    data() {
      return {
        changelogs: [],
        loading: true,
        error: null
      };
    },
    async created() {
      try {
        const response = await apiService.fetchChangelogs();
        this.changelogs = response;
        this.loading = false;
      } catch (error) {
        console.error('Error fetching changelogs:', error);
        this.error = 'Failed to fetch changelogs.';
        this.loading = false;
      }
    },
    methods: {
      formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
      }
    }
  };
  </script>
  
  <style scoped>
  .changelog-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #292929;
    color: #f3f3f3;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
  }
  
  .loading, .error {
    text-align: center;
    margin-bottom: 20px;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li.log-item {
    display: flex;
    margin-bottom: 15px;
    border-bottom: 1px solid #444;
    padding-bottom: 10px;
  }
  
  .log-date {
    width: 150px;
    font-weight: bold;
    color: #bbb;
    margin-right: 10px;
  }
  
  .log-content {
    flex: 1;
  }
  
  .log-text {
    margin: 0;
  }
  
  .log-type {
    color: #e91e63;
    font-weight: bold;
  }
  
  .log-reviewer {
    color: #ff9800;
  }
  
  .log-album {
    color: #00bcd4;
    font-weight: bold;
  }
  
  .log-rating {
    color: #8bc34a;
    font-weight: bold;
  }
  </style>
  